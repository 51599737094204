$(function () {
   objectFitImages();
});

//Chosen-select
$(document).ready(function () {
    chosenInit();
});

function chosenInit() {
    $(".chosen:not(.parking-chosen)").chosen({
        search_contains: true,
        no_results_text: "Ничего не найдено по запросу"
    });
}

//Range slider
$(function() {
    $('.range-slider').each(function () {
        var slider = $(this).find('.slider-range'),
            min = $(this).find(".amount-from"),
            max = $(this).find(".amount-to");

        //range slider init
        slider.slider({
            range: true,
            min: parseInt(min.attr('min')),
            max: parseInt(max.attr('max')),
            values: [ min.val(), max.val() ],
            slide: function( event, ui ) {
                min.val(ui.values[ 0 ]);
                max.val(ui.values[ 1 ]);
            }
        });

        //range separators draw
        var separators = $(this).find('.separators'),
            currency = separators.data('currency'),
            separatorsLength = separators.data('count');
        for (var i=1; i <= separatorsLength; i++) {
            separators.append('' +
                '<span style="left:' + (100/(separatorsLength-1)*(i-1)) + '%"><span>' + parseInt(((parseInt(max.attr('max')) - parseInt(min.attr('min')))/(separatorsLength - 1)*(i-1)) + parseInt(min.attr('min'))) + currency + '</span></span>' +
                '')
        }
    });

    //dynamic range change
    $('.amount-from, .amount-to').keyup(function () {
        if (parseInt($(this).closest('.range-slider').find(".amount-from").val()) <= parseInt($(this).closest('.range-slider').find(".amount-to").val())) {
            $(this).closest('.range-slider').find(".slider-range").slider( "values", [ $(this).closest('.range-slider').find(".amount-from").val(), $(this).closest('.range-slider').find(".amount-to").val()] );
        }
    });

    //range inputs validation
    $('.amount-from, .amount-to').on('change', function () {
        if ($(this).val() > parseInt($(this).attr('max'))) {
            $(this).val(parseInt($(this).attr('max')));
        }
    });
});

//Single slider
$(function () {

    $('.range-single').each(function () {
        var slider = $(this).find('.slider-range'),
            value = $(this).find(".value");

        //range slider init
        slider.slider({
            range: false,
            min: parseInt(value.attr('min')),
            max: parseInt(value.attr('max')),
            value: [toNumber(value.val())],
            slide: function (event, ui) {
                value.val(numberWithSpaces(ui.value));
            },
            create: function (event, ui) {

            }
        });

        //range separators draw
        var separators = $(this).find('.separators'),
            currency = separators.data('currency'),
            separatorsLength = separators.data('count');
        for (var i = 1; i <= separatorsLength; i++) {
            separators.append('' +
                '<span style="left:' + (100 / (separatorsLength - 1) * (i - 1)) + '%"><span>' + numberWithSpaces(parseInt(((parseInt(value.attr('max')) - parseInt(value.attr('min'))) / (separatorsLength - 1) * (i - 1))) + parseInt(value.attr('min'))) + currency + '</span></span>' +
                '')
        }
    });

    // dynamic range change
    $('.range-single .value').keyup(function () {
        var slider = $(this).closest('.range-single').find(".slider-range"),
            value = $(this);
        slider.slider( "value", toNumber(value.val()));
        value.val(numberWithSpaces(value.val()));
    })
        // range inputs validation
        .on('change', function () {
        if (toNumber($(this).val()) > parseInt($(this).attr('max'))) {
            $(this).val(numberWithSpaces($(this).attr('max')));
        }
    });
});

//Tooltip
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

//Cart item remove from DOM
function removeItem(thisObj) {
    var parent = thisObj.closest('.cart-item');
    parent.fadeTo(300, 0.0, function () {
        parent.slideUp('fast', function () {
            parent.remove();
        });
    })
}

// Internet Explorer 6-11
var isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;

$(document).ready(function(){
    if (isIE == true || isEdge == true) {
        $('body').addClass('ie-only');
    }
});

//Add spaces to number
function numberWithSpaces(x) {
    return x.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

//Remove spaces from number
function toNumber(x) {
    return x.replace(/\D/g, '');
}